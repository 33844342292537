export const urlConstant = {
  //common urls
  GET_POWER_PLANT_OPTIONS: 'power-plants',
  GET_ASSET_GROUP_OPTIONS: 'asset/groups',

  //user setting
  GET_USER_SETTING_URL: 'user',
  POST_USER_SETTING_URL: 'user',
  PATCH_USER_SETTING_URL: 'user',

  // user preference
  USER_PREFERENCE: 'user-preference',

  // reference plant unit list
  GET_AUTH_UNIT_LIST: 'user/reference-plant-unit-list',

  // UserRole
  USER_ROLE_URL: 'user/role',

  // Disaster urls
  GET_DISASTER: 'disaster/status/recent',
  POST_DISASTER: 'disaster/status',
  PUT_DISASTER: 'disaster/status',

  POWER_PLANTS: 'powerplants',
  DISASTERS: 'disasters',
  HAZARD_POINTS: 'hazard-points',
  HAZARD_AREA: 'hazard-area',
  USER_SAFETY_LIST: 'safety/list',
  EVACUATION_SITES: 'evacuation-sites',
  OPERATORS: 'operators',
  WEATHER: 'weather',
  POWER_PLANT_PARTNER: 'power_plant_partner',
  DISASTER_ITEMS: 'disaster-items',
  USERS: 'users',
  EVACUATION: 'evacuation',
  HISTORY: 'history',
  LATEST: 'latest',
  MAP: 'map',
  USER_INFO: 'user-info',
  CREDENTIALS: 'credentials',
  REFRESH: 'refresh',
  LOCATIONS: 'locations',
  QR_CODE: 'qr-code',
  INSTALL_ANDROID_APP_LATEST: 'install/android-app/latest',
}

export const urlParametersConstant = {
  POWER_PLANT_ID: 'power-plant-id',
  ASSET_GROUP_ID: 'asset-group-id',
  POWER_PLANT_UNIT_ID: 'power-plant-unit-id',
  USER_ID: 'user-id',
  LIMIT: 'limit',
  OFFSET: 'offset',
  PARENT_ID: 'parent-id',
  PLANT_CODE: 'plant_code',
  PPM: 'ppm',
  DISASTER_ID: 'disaster_id',
  PLANT_ID: 'plant_id',
  TENANTS: 'tenants',
  CODE: 'code',
} as const

export const userConstants = {
  isLoggedIn: 'isLoggedIn',
  userInfo: 'userInfo',
  accessToken: 'accessToken',
  userSetting: 'userSetting',
  userPreferenceMasterData: 'userPreferenceMasterData',
  language: 'language',
  filterSetting: 'filterSetting',
  user_preference: 'user-preference',
  registered_powerplant: 'registered-powerplant',
  SaaSusIdToken: 'SaaSusIdToken',
  GUEST: 'GUEST'
}

export const routesConstant = {
  LANDING_PAGE: '/',
  USER_LOGIN: '/user-login',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  DOWNLOAD_APK: '/download-apk',
  APPLICATION_QR_CODE: '/application-qr-code',
}

export const publicRoutesConstant = [
  routesConstant.TERMS_AND_CONDITIONS,
  routesConstant.DOWNLOAD_APK,
]

export const appConstant = {
  USER_ID: 'user_id',
  HAZARD_AREA: 'hazard_area',
} as const

export const roleNames = {
  app_admin: 'app_admin',
  security_guard: 'security_guard',
  general_user: 'general_user',
} as const

// alert types for ui kit
export enum alertType {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

// marker types for map
export const markerType = {
  INCIDENT: 'incident',
  FIRE: 'FIRE',
} as const

// user safety status during disaster
export const userStatus = {
  SAFE: 'SAFE',
  MINOR_INJURY: 'MINOR_INJURY',
  SERIOUS_INJURY: 'SERIOUS_INJURY',
  NOT_ANSWERED: 'NOT_ANSWERED',
} as const

export const qrCodeType = {
  INSTALL_IOS_APP: 'INSTALL_IOS_APP',
  INSTALL_ANDROID_APP: 'INSTALL_ANDROID_APP',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
} as const

export const userEvacuationStatus = {
  EVACUATION_COMPLETED: 'EVACUATION_COMPLETED',
  MOVING: 'MOVING',
  NO_MOVEMENT: 'NO_MOVEMENT',
  UNAVAILABLE: 'UNAVAILABLE',
  STATUS_BLANK: 'STATUS_BLANK',
} as const
export const disasterType = {
  AMMONIA_LEAKS: 'AMMONIA_LEAKS',
  FIRE: 'FIRE',
} as const
export const powerPlantPreventionItemsSettingConstant = {
  EXTINGUISHING_OUTDOOR_HYDRANT: 'EXTINGUISHING_OUTDOOR_HYDRANT',
  OUTDOOR_FIRE_HYDRANT_65: 'OUTDOOR_FIRE_HYDRANT_65',
  OUTDOOR_FIRE_HYDRANT_50: 'OUTDOOR_FIRE_HYDRANT_50',
  AUXILIARY_FOAM_FIRE_STOPPER_65: 'AUXILIARY_FOAM_FIRE_STOPPER_65',
  AED: 'AED',
  SANDBAG: 'SANDBAG',
  DISASTER_MATERIALS_EQUIPMENT: 'DISASTER_MATERIALS_EQUIPMENT',
  PROTECTIVE_EQUIPMENT: 'PROTECTIVE_EQUIPMENT',
  HANDSET: 'HANDSET',
  DEFAULT_DISASTER_LOCATION: 'DEFAULT_DISASTER_LOCATION',
  LOCAL_COMMAND_HQ_VEHICLE: 'LOCAL_COMMAND_HQ_VEHICLE',
  EMERGENCY_SHOWER: 'EMERGENCY_SHOWER',
} as const
//show wind icon circle if wind direction is 0 degree
export const ZERO_WIND_DIRECTION = 0

//wind speed unit
export const WIND_SPEED_UNIT = 'm/s'

//wind direction subtraction factor to consider left axis as 90 degree
export const WIND_DIRECTION_SUBTRACTION_FACTOR = 180

export const FETCH_USER_SAFETY_LIST_AFTER_MINUTES = 1 //1
export const FETCH_HAZARD_AREA_AFTER_MINUTE = 1 //1
export const FETCH_HAZARD_AREA_AFTER_MINUTES = 30 //30
export const FETCH_WIND_DIRECTION_AFTER_MINUTES = 30 //30
export const FETCH_USER_LOCATION_AFTER_MINUTE = 1 //1
export const FETCH_USER_LOCATION_AFTER_FIRST_TIME = 10/60 // x/60 -> x seconds
export const FETCH_PREVENTION_ITEMS_AFTER_MINUTES = 5 //5
export const FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE = 1 //1
export const FETCH_LATEST_DISASTER_AFTER_MINUTES = 1 //1

// set which users can access which routes
export const routesAccess = {
  [routesConstant.APPLICATION_QR_CODE]: [roleNames.security_guard],
} as const

export const mapZoomConfig = {
  minZoomForDoubleClick: 16,
} as const

export const toasterConfig = {
  timeoutMs: 5000, // Note: also change in styles if changed here
} as const

export const USER_EVACUATION_STATUSES = {
  NO_MOVEMENT: 'NO_MOVEMENT',
  MOVING: 'MOVING',
  EVACUATION_COMPLETED: 'EVACUATION_COMPLETED',
  UNAVAILABLE: 'UNAVAILABLE',
  STATUS_BLANK: 'STATUS_BLANK',
} as const

export const USER_SAFETY_STATUSES = {
  SAFE: 'SAFE',
  MINOR_INJURY: 'MINOR_INJURY',
  SERIOUS_INJURY: 'SERIOUS_INJURY',
  NOT_ANSWERED: 'NOT_ANSWERED',
} as const

export const DASH = 'ー';
