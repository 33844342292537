import {
  FETCH_LATEST_DISASTER_AFTER_MINUTES,
} from '../../../appConstants'
import { DisasterService } from '../../../services/disasterRiskManagement/DisasterRiskManagementService'
   
export function refetchLatestDisasterInterval(
  response:
    | Awaited<ReturnType<typeof DisasterService.fetchDisasterStatus>>
    | undefined
) {
  const latestDisasterStatus = response?.data.status
  const msInMin = 60 * 1000
  // if there is disaster, call after specified interval
  return latestDisasterStatus
    ? FETCH_LATEST_DISASTER_AFTER_MINUTES * msInMin
    : false
}