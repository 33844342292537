export function addFavIcons() {
  // take S3 bucket's url from env vars
  const REACT_APP_S3_URL = process.env.REACT_APP_S3_URL

  if (!REACT_APP_S3_URL) {
    return
  }

  const links = [
    {
      rel: 'icon',
      type: 'image/png',
      href: 'favicon-96x96.png',
      sizes: '96x96',
    },
    { rel: 'icon', type: 'image/svg+xml', href: 'favicon.svg' },
    { rel: 'shortcut icon', href: 'favicon.ico' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: 'apple-touch-icon.png' },
    { rel: 'manifest', href: 'site.webmanifest' },
  ]

  // create link elements and append them to head tag
  links.forEach(({ rel, type, href, sizes }) => {
    const link = document.createElement('link')
    link.rel = rel
    if (type) {
      link.type = type
    }
    link.href = REACT_APP_S3_URL + `favicon_hsse/${href}`
    if (sizes) {
      (link as any).sizes = sizes
    }
    document.head.appendChild(link)
  })
}
