import * as React from 'react'
import {
  QueryClient,
  QueryCache,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query'
import { useMemo } from 'react'
import { useAppContext } from '../../hooks/AppContextProvider'
import { getErrorMessage } from '../../../../utils/handleHttpResponseUtils'
import { useTranslation } from 'react-i18next'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import i18n from '../../../../translations/i18n'
import { alertType, toasterConfig } from '../../../../appConstants'
import { clearAllToasts, createToast } from './ToasterProvider'
import { AlertMessage } from '../../hooks/AppContextProvider/types'

export function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { error, alert } = useAppContext() ?? {}
  const { setMessage, errorMessage } = error
  const { alertMessage, setAlertMessage } = alert
  const { t } = useTranslation()

  function closeAlertMessage() {
    setAlertMessage({ type: alertType.DANGER, message: '' })
  }

  // track prev toast message
  // so that not to show that toast again and again
  // if occurs between a given interval
  const prevToastMessage = React.useRef<string | null>(null)
  const resetPrevErrorTimeoutRef = React.useRef<ReturnType<
    typeof setTimeout
  > | null>(null)

  // set prev message ref
  const setPrevToastMessage = React.useCallback((val: string) => {
    if (resetPrevErrorTimeoutRef.current) {
      clearInterval(resetPrevErrorTimeoutRef.current)
    }
    prevToastMessage.current = val

    // after timeout seconds, reset prevError
    // so that same message can be displayed again
    resetPrevErrorTimeoutRef.current = setTimeout(() => {
      prevToastMessage.current = null
      setAlertMessage({ type: alertType.DANGER, message: '' });
      clearTimeout(resetPrevErrorTimeoutRef.current as any)
    }, toasterConfig.timeoutMs)
  }, [setAlertMessage])

  // create a toast if already not created at last
  // (before a defined interval)
  const createToastIfNotAlready = React.useCallback(
    (params: AlertMessage) => {
      const { message, type } = params
      if (message && prevToastMessage.current !== message) {
        createToast({
          type,
          message,
        })
        setPrevToastMessage(message)
      }
    },
    [setPrevToastMessage]
  )

  const queryClient = useMemo(() => {
    return new QueryClient({
      queryCache: new QueryCache({
        onError: (error) => {
          // handle errors globally here
          console.log('global error handling', error)
          const message = getErrorMessage(error)
          if (message) {
            const translatedMessage = i18n.t(message)
            createToastIfNotAlready({
              type: alertType.DANGER,
              message: translatedMessage,
            })
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (error) => {
          // handle errors globally here
          const message = getErrorMessage(error)
          if (message) {
            const translatedMessage = i18n.t(message)
            createToastIfNotAlready({
              type: alertType.DANGER,
              message: translatedMessage,
            })
          }
        },
      }),
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 0,
        },
      },
    })
  }, [createToastIfNotAlready])

  React.useEffect(() => {
    // handle alerts
    const translatedMessage = i18n.t(alertMessage.message)
    if (!translatedMessage) {
      // if it was cleared from some place else
      // clear prev stored ref so toast can be shown immediately
      prevToastMessage.current = null
      return
    }
    createToastIfNotAlready({ message: translatedMessage, type: alertMessage.type })

    // cleanup, remove toasts
    return function () {
      clearAllToasts()
    }
  }, [alertMessage.message, alertMessage.type, createToastIfNotAlready])

  return (
    <QueryClientProvider client={queryClient}>
      <>
        {children}
      </>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
