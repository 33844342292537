import { useQuery } from "@tanstack/react-query"
import { useAuth } from "../../context/login/components/organisms/Protected/UserLogin"
import { DisasterService } from "../../services/disasterRiskManagement/DisasterRiskManagementService"
import { roleNames, routesConstant } from "../../appConstants";
import { useCurrentRoute } from "react-navi";

export function useMapCoordinates() {
  const { currentTenantId, role } = useAuth()
  const pathname = useCurrentRoute()?.url?.pathname;

  return useQuery({
    queryKey: ['map', currentTenantId],
    enabled: !!currentTenantId && (role === roleNames.app_admin) && pathname === routesConstant.LANDING_PAGE,
    queryFn: () => DisasterService.fetchMapCoordinates(currentTenantId),
  })
}

export function useIsCorrectMapCoordinatesResponse() {
  const response = useMapCoordinates().data?.data

  const correctResponse =
    response &&
    Object.keys(response)?.length > 0 &&
    response?.controll_limit_pc?.geometry?.coordinates?.length > 0 &&
    response?.map_center?.coordinates?.length > 0

  return !!correctResponse;
}