import { useQuery, useQueryClient } from "@tanstack/react-query"
import { FETCH_USER_LOCATION_AFTER_MINUTE } from "../../appConstants"
import { useAuth } from "../../context/login/components/organisms/Protected/UserLogin"
import { useIsCorrectMapCoordinatesResponse } from "./useMapCoordinates";
import { DisasterService } from "../../services/disasterRiskManagement/DisasterRiskManagementService";
import { refetchLatestDisasterInterval } from "../../pages/newDashboard/common/refetchLatestDisaster";

export function useUserLocationsQuery() {
  const { currentTenantId } = useAuth();
  const queryClient = useQueryClient();  
  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  const { data: latestDisasterStatus } = useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
    refetchInterval: refetchLatestDisasterInterval,
  })

  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined


  return useQuery({
    queryKey: ['user-location', currentTenantId],
    enabled: !!disasterId && !!currentTenantId,
    queryFn: () => DisasterService.fetchUserLocations(currentTenantId),
    refetchInterval(_data, query) {
      const msInMin = 60 * 1000
      return FETCH_USER_LOCATION_AFTER_MINUTE * msInMin
    },
    onSuccess(response) {
      const disasterIdFromLocation = response.data.disaster_id
      // check location APi response
      // if `disaster_id` from locations API does not match current disaster id, refetch latest API
      if (disasterIdFromLocation && disasterId && (disasterIdFromLocation != disasterId)) {
        queryClient.invalidateQueries({
          queryKey: ['disaster-status', currentTenantId],
        })
      }
    },
  })
}