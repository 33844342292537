import React, { useEffect, useReducer } from 'react'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { useQuery } from '@tanstack/react-query'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'

import maplibregl from 'maplibre-gl'
import type { Map as MapLibreMap, LngLatBoundsLike, Marker } from 'maplibre-gl'
import Envs from '../../Envs'
import {
  disasterType,
  FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE,
  FETCH_USER_SAFETY_LIST_AFTER_MINUTES,
  markerType,
} from '../../appConstants'
import { refetchLatestDisasterInterval } from './common/refetchLatestDisaster'

import icnRedUser from '../../assets/images/icn-red-user.svg'
import icnGreenUser from '../../assets/images/icn-user-green-70.svg'
import icnGreyUser from '../../assets/images/icn-user-grey.svg'
import icnOrangeUser from '../../assets/images/icn-user-orange.svg'
import icnClusterUsers from '../../assets/images/icn-grey-user.svg'
import icnZoomIn from '../../assets/images/icn-zoom-in.svg'
import icnZoomOut from '../../assets/images/icn-zoom-out.svg'
import NoDataFound from '../../components/common/NoDataFound'

export type MapProps = {
  tableData: FetchUserSafetyListRes
  setTableData: SetState<FetchUserSafetyListRes>
  mapObject: null | MapLibreMap
  setMapObject: SetState<MapLibreMap | null>
}

type State = {
  disasterPlaceConfirmModal: boolean
  coordinates: { lat: number; lng: number } | null
}

// TODO: move to constants and replace existing enum in app constant
const DISASTER_TYPES = {
  AMMONIA_LEAKS: 'AMMONIA_LEAKS',
  FIRE: 'FIRE',
} as const

export function Map({
  tableData,
  setTableData,
  mapObject,
  setMapObject,
}: MapProps) {
  const { currentTenantId, role } = useAuth()
  const { t } = useTranslation()

  // map coordinates
  const {
    data: mapCoorDinatesData,
    isLoading: mapCoordinatesLoading,
    error: mapCoordinatesError,
    isFetching: mapCoorDinatesFetching
  } = useMapCoordinates()

  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  // latest disaster info
  const {
    data: latestDisasterStatus,
    isLoading: latestDisasterStatusLoading,
    error: latestDisasterStatusError,
  } = useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
    refetchInterval: refetchLatestDisasterInterval,
  })

  const disasterType = latestDisasterStatus?.data.disaster_type
  const isTrainingPracticeFlag = latestDisasterStatus?.data.practice_flag
  const hasDisaster = latestDisasterStatus?.data.status
  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined

  // Safety List table logic start ----->
  // @TODO: move logic to safety list component
  const { data: safetyListApiData } = useQuery({
    queryKey: ['user-safety-list', disasterId],
    enabled: !!disasterId,
    refetchInterval: FETCH_USER_SAFETY_LIST_AFTER_MINUTES * 60 * 1000,
    queryFn: () =>
      DisasterService.fetchUserSafetyList(
        Number(`${disasterId}`),
        currentTenantId
      ),
  })

  // Safety List table logic end ----->

  const {
    data: disasterAreaData,
    isLoading: disasterAreaLoading,
    error: disasterAreaError,
  } = useQuery({
    queryKey: ['disaster-area', currentTenantId, disasterId],
    enabled: !!currentTenantId && !!disasterId,
    queryFn: () =>
      DisasterService.fetchDisasterArea(currentTenantId, disasterId as number),
    refetchInterval: reFetchHazardAreaInterval,
  })

  const disasterLocationRegistered = disasterAreaData?.data?.hazard_point
    ?.geometry
    ? disasterAreaData?.data?.hazard_point?.geometry
    : undefined

  useQuery({
    queryKey: ['evacuation-history', disasterId],
    enabled: !!disasterId && !!disasterLocationRegistered,
    refetchInterval: FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE * 60 * 1000,
    queryFn: () =>
      DisasterService.fetchEvacuationStatus(
        currentTenantId,
        Number(`${disasterId}`)
      ),
  })

  const {
    data: evacuationSitesData,
    isLoading: evacuationSitesLoading,
    error: evacuationSitesError,
  } = useQuery({
    queryKey: ['evacuation-sites', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchAllEvacationSites(currentTenantId),
  })

  const [mapState, updateMapState] = useReducer(
    function (prev: State, next: Partial<State>) {
      return { ...prev, ...next }
    },
    {
      disasterPlaceConfirmModal: false,
      coordinates: null,
    } as State
  )

  useEffect(() => {
    const response = mapCoorDinatesData?.data
    const correctResponse =
      response &&
      Object.keys(response)?.length > 0 &&
      response?.controll_limit_pc?.geometry?.coordinates?.length > 0 &&
      response?.map_center?.coordinates?.length > 0

    if (!correctResponse) {
      return
    }

    const identityPoolId = Envs.ALS_IDENTITY_POOL_ID || ''
    const apiKey = response?.map_key?.[0]?.key
    const cords = response?.controll_limit_pc?.geometry?.coordinates

    const bounds: LngLatBoundsLike = [
      [cords?.[0]?.[1]?.[0], cords?.[0]?.[1]?.[1]],
      [cords?.[0]?.[3]?.[0], cords?.[0]?.[3]?.[1]],
    ]
    const mapName = Envs.AWS_MAP_NAME
    const region = identityPoolId.split(':')[0]

    const map = new maplibregl.Map({
      container: 'map',
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [
        response?.map_center?.coordinates[0],
        response?.map_center?.coordinates[1],
      ],
      zoom: 15,
      maxZoom: 20,
      minZoom: 15,
      maxBounds: bounds,
      doubleClickZoom: false,
      dragRotate: false,
      touchZoomRotate: false,
      attributionControl: false,
    })

    const zoomInButton = document.getElementById('zoom-in')
    const zoomOutButton = document.getElementById('zoom-out')

    // Function to update zoom button states based on current zoom level
    const updateZoomButtons = () => {
      const zoomLevel = map.getZoom()
      const minZoom = map.getMinZoom()
      const maxZoom = map.getMaxZoom()

      if (zoomLevel >= maxZoom) {
        zoomInButton?.disabled = true
        zoomInButton?.style.opacity = 0.5 // Optional visual cue for disabled button
      } else {
        zoomInButton?.disabled = false
        zoomInButton?.style.opacity = 1
      }

      // Enable/disable zoom out button
      if (Math.round(zoomLevel) <= minZoom) {
        zoomOutButton?.disabled = true
        zoomOutButton?.style.opacity = 0.5 // Optional visual cue for disabled button
      } else {
        zoomOutButton?.disabled = false
        zoomOutButton?.style.opacity = 1
      }
    }

    // Event listeners for zoom in and zoom out
    zoomInButton.addEventListener('click', () => {
      map.zoomIn()
    })

    zoomOutButton.addEventListener('click', () => {
      map.zoomOut()
    })

    // Update the button states on map zoom
    map.on('zoom', updateZoomButtons)

    // Initialize the button states when the map is loaded
    map.on('load', updateZoomButtons)

    setMapObject(map)
    map.on('load', () => {
      // Initialize the button states when the map is loaded
      updateZoomButtons()
      const img1 = new Image()
      img1.src = icnRedUser
      img1.height = 40
      img1.width = 40
      img1.onload = () => {
        if (!map.hasImage('user-red-location')) {
          map.addImage('user-red-location', img1)
        }
      }
      const img2 = new Image()
      img2.src = icnGreenUser
      img2.height = 40
      img2.width = 40
      img2.onload = () => {
        if (!map.hasImage('user-green-location')) {
          map.addImage('user-green-location', img2)
        }
      }

      const img3 = new Image()
      img3.src = icnGreyUser
      img3.height = 40
      img3.width = 40
      img3.onload = () => {
        if (!map.hasImage('user-grey-location')) {
          map.addImage('user-grey-location', img3)
        }
      }

      const img4 = new Image()
      img4.src = icnOrangeUser
      img4.height = 40
      img4.width = 40
      img4.onload = () => {
        if (!map.hasImage('user-orange-location')) {
          map.addImage('user-orange-location', img4)
        }
      }
    })

    const imgCluster = new Image()
    imgCluster.src = icnClusterUsers
    imgCluster.onload = () => {
      if (!map.hasImage('users-cluster')) {
        map.addImage('users-cluster', imgCluster)
      }
    }

    return () => {
      try {
        map.remove()
        setMapObject(null)
      } catch (error) {
        console.error(error)
      }
    }
  }, [mapCoorDinatesData, setMapObject])

  // set disaster location into map
  useEffect(() => {
    let markerCopy: Marker | null = null
    const data = disasterAreaData?.data
    if (!(data && mapObject && hasDisaster)) {
      return
    }
    const disasterAreaResponse = disasterAreaData?.data
    const hazardPointGeometry = disasterAreaResponse?.hazard_point?.geometry
    // const mergedGeometries = [...hazardAreaGeometries, hazardPointGeometry]
    const updatedMarkerType =
      disasterType === markerType.FIRE ? markerType.FIRE : markerType.INCIDENT

    const el = createDisasterMarker(updatedMarkerType)

    if (hazardPointGeometry) {
      const longitude = (hazardPointGeometry as PointGeometry)?.coordinates?.[0]
      const latitude = (hazardPointGeometry as PointGeometry)?.coordinates?.[1]
      if (!(latitude && longitude)) {
        return
      }
      const marker = new maplibregl.Marker({ element: el })
        .setLngLat([longitude, latitude])
        .addTo(mapObject)
      markerCopy = marker
    }

    // cleanup: remove disaster marker
    return () => {
      if (markerCopy) {
        markerCopy.remove()
      }
    }
  }, [disasterAreaData?.data, disasterType, hasDisaster, mapObject])

  // set hazard area into map
  useEffect(() => {
    const data = disasterAreaData?.data
    const correctHazardAreaData = data && data.hazard_area?.geometry?.length > 0
    const disasterType = data?.disaster_type
    const isAmmoniaDisaster = disasterType === DISASTER_TYPES.AMMONIA_LEAKS // add hazard area only in case of ammonia leaks

    if (
      !(
        data &&
        mapObject &&
        hasDisaster &&
        correctHazardAreaData &&
        isAmmoniaDisaster
      )
    ) {
      return
    }

    const map = mapObject

    // Reference to the 'style.load' event listener
    let styleLoadListener: null | (() => void) = null

    // Function to safely add hazard areas
    const safelyAddHazardAreas = () => {
      addHazardAreasToMap(data, map)
    }

    // Check if style is already loaded or wait for it
    if (map.isStyleLoaded()) {
      safelyAddHazardAreas()
    } else {
      // Store the reference to the 'style.load' event listener
      styleLoadListener = () => {
        safelyAddHazardAreas()
      }
      map.once('style.load', styleLoadListener)
    }

    // cleanup: remove hazard area
    return () => {
      if (mapObject && mapObject.isStyleLoaded()) {
        mapObject.getLayer('hazard-area-layer-yellow') &&
          mapObject.removeLayer('hazard-area-layer-yellow')
        mapObject.getSource('hazard-area-yellow') &&
          mapObject.removeSource('hazard-area-yellow')
        mapObject.getLayer('hazard-area-layer-red') &&
          mapObject.removeLayer('hazard-area-layer-red')
        mapObject.getSource('hazard-area-red') &&
          mapObject.removeSource('hazard-area-red')
      }

      // Remove the 'style.load' listener if it was added
      if (styleLoadListener) {
        map.off('style.load', styleLoadListener)
      }
    }
  }, [disasterAreaData?.data, hasDisaster, mapObject])

  // handle evacuation sites markers
  useEffect(() => {
    const evacuationSites = evacuationSitesData?.data
    const map = mapObject
    if (!(evacuationSites && map)) {
      return
    }
    const evacuationMarkers: Marker[] = []

    evacuationSites?.features?.forEach((markerData: any) => {
      const createdEvacuationMarker = createEvacuationMarker(markerData)
      evacuationMarkers.push(createdEvacuationMarker)
      createdEvacuationMarker.addTo(map)
    })

    return () => {
      // cleanup: remove evacuation markers from map
      evacuationMarkers.forEach((evacuationMarker) => evacuationMarker.remove())
    }
  }, [evacuationSitesData?.data, mapObject])

  if (
    !mapCoordinatesLoading &&
    !mapCoorDinatesFetching &&
    !mapCoordinatesSuccess
  ) {
    return (
      <div className="uk-margin-auto-left uk-margin-auto-right" style={{marginTop: '-5%'}}>
        <NoDataFound msg='TITLES.MAP_DATA_NOT_FOUND_MESSAGE' />
      </div>
    )
  }

  return (
    <>
      <div id="map" className="map-section img-map"></div>
      <div className="custom-zoom-controls">
        {/*<!-- Custom SVG button for zoom in --> */}
        <button disabled id="zoom-in">
          <img src={icnZoomIn} alt="zoom-in.png" />
        </button>
        {/*<!-- Custom SVG button for zoom out --> */}
        <button id="zoom-out">
          <img src={icnZoomOut} alt="zoom-out.png" />
        </button>
      </div>
    </>
  )
}

import iconFire from '../../assets/images/fireIcon.svg'
import cancel from '../../assets/images/icons/cir.svg'
import iconAccident from '../../assets/images/icons/icn-danger-disaster.svg'
import iconEvacuation from '../../assets/images/icons/icn-evacuation-site.svg'
import type {
  EvacuationSiteFeature,
  FetchUserSafetyListRes,
  HazardAreaBody,
  Operator,
  PointGeometry,
  User,
} from '../../data/types/DisasterRiskManagement'
import { useTranslation } from 'react-i18next'
import ConfirmBoxModal from '../../components/common/ConfirmBoxModal'
import { useSafetyListTableData } from '../../hooks/disasterRiskManagement/useSafetyListTableData'
import { SetState } from '../../data/types/common'
import { reFetchHazardAreaInterval } from './common/refetchHazardArea'
import { useIsCorrectMapCoordinatesResponse, useMapCoordinates } from '../../hooks/queries/useMapCoordinates'
function createDisasterMarker(type?: string) {
  const el = document.createElement('div')
  el.style.backgroundSize = 'cover'
  el.style.backgroundPosition = 'center'
  el.style.backgroundRepeat = 'no-repeat'
  el.style.zIndex = '1'

  switch (type) {
    case disasterType.FIRE:
      el.style.backgroundImage = `url(${iconFire})`
      el.style.width = '100px'
      el.style.height = '100px'
      el.style.margin = '-12px -5px -8px 0px'
      break

    default:
      el.style.backgroundImage = `url(${iconAccident})`
      el.style.width = '100px'
      el.style.height = '100px'
      el.style.margin = '-44px -5px -8px 0px'
      break
  }

  return el
}

// Adding hazards area on map
function addHazardAreasToMap(hazardArea: HazardAreaBody, map: MapLibreMap) {
  const hazardAreaGeometries = Array.isArray(hazardArea?.hazard_area?.geometry)
    ? hazardArea?.hazard_area?.geometry
    : []
  const hazardPointGeometry = hazardArea?.hazard_point?.geometry
    ? [hazardArea?.hazard_point?.geometry]
    : []

  // Combine hazard_area and hazard_point geometries
  const hazardGeometries = [...hazardAreaGeometries, ...hazardPointGeometry]
  if (
    hazardArea &&
    hazardArea?.hazard_area &&
    hazardArea?.hazard_point?.geometry?.coordinates?.length > 0
  ) {
    let hazardRedCoordinates: any = []
    let hazardYellowCoordinates: any = []
    hazardGeometries?.forEach((hazardAreaData: any, index: any) => {
      if (
        hazardAreaData?.coordinates?.length > 0 &&
        hazardAreaData?.type === 'Polygon'
      ) {
        if (index === 0) {
          hazardYellowCoordinates = hazardAreaData.coordinates
        } else if (index === 1) {
          hazardRedCoordinates = hazardAreaData.coordinates
        }
      }
    })

    const hazardAreaDataRed = [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: hazardRedCoordinates,
        },
        properties: {
          color: 'rgba(197, 34, 45, 0.7)',
          description: 'Hazard Area Red', // Add appropriate description here
        },
      },
    ]

    const hazardAreaDataYellow = [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: hazardYellowCoordinates,
        },
        properties: {
          color: 'rgba(240, 170, 84, 0.6)',
          description: 'Hazard Area Yellow', // Add appropriate description here
        },
      },
    ]

    if (!map.getSource('hazard-area-yellow')) {
      map.addSource('hazard-area-yellow', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: hazardAreaDataYellow,
        },
      })
    }
    if (!map.getLayer('hazard-area-layer-yellow')) {
      map.addLayer({
        id: 'hazard-area-layer-yellow',
        type: 'fill',
        source: 'hazard-area-yellow',
        paint: {
          'fill-color': '#F0AA54',
          'fill-opacity': 0.4,
        },
        layout: {
          visibility: 'visible',
        },
      })
    }
    if (!map.getSource('hazard-area-red')) {
      map.addSource('hazard-area-red', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: hazardAreaDataRed,
        },
      })
    }
    if (!map.getLayer('hazard-area-layer-red')) {
      map.addLayer({
        id: 'hazard-area-layer-red',
        type: 'fill',
        source: 'hazard-area-red',
        paint: {
          'fill-color': '#C5222D',
          'fill-opacity': 0.5,
        },
        layout: {
          visibility: 'visible',
        },
      })
    }
  }
}

function createEvacuationMarker(feature: EvacuationSiteFeature) {
  const { coordinates } = feature?.geometry
  const markerElement = document.createElement('div')
  const imageElement = document.createElement('img')
  const markerContainer = markerElement.appendChild(imageElement)
  markerContainer.setAttribute('src', iconEvacuation)
  markerElement.className = 'evacuation-site'
  markerElement.style.height = '60px'
  markerElement.style.width = '60px'
  markerElement.style.margin = '-16px -5px -8px 0px'
  markerElement.style.background = 'transparent'
  imageElement.style.width = '60px'
  imageElement.style.height = '60px'
  imageElement.style.zIndex = '1'

  const newMarker = new maplibregl.Marker({
    element: markerElement,
  }).setLngLat(coordinates)

  return newMarker
}
